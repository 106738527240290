<!--
 Name: Header.vue
 Description: Component to display the header area.
 Created by: Jana Schumann on 2019-11-06
 Last edited by: Lokeswari Madhusudhana on 2023-07-10
-->

<template>
  <!-- if the height will be changed in the future don't forget to change y-offset in the router -->
  <!-- extension-height="0" to make $vuetify.goTo work correctly -->
    <v-app-bar
      elevation="5"
      class="app-header padded-block"
      height="96"
      @mouseleave="openSubMenu = false"
      extension-height="0"
    >
      <!-- menu -->
      <v-row no-gutters justify="space-between" class="pr-9">
        <v-col cols="2" offset="1">
          <router-link :to="'/' + $i18n.locale" class="text-decoration-none">
            <v-img
              :src="logo_large"
              width="180px"
              class="hidden-xs"
              @click="openSubMenu = false"
            />
            <v-img
              :src="logo_small"
              width="50px"
              class="hidden-sm-and-up"
              @click="openSubMenu = false"
            />
          </router-link>
        </v-col>

        <v-col md="6" align-self="center" class="hidden-sm-and-down">
          <v-row no-gutters justify="space-around">
            <!-- neotiv -->
            <v-col
              v-for="(submenu, idx) in mainMenu"
              :cols="colsWidths[$i18n.locale][idx]"
              :key="submenu.headline"
              class="menu-link pr-7"
              @mouseover="openSubMenu = true"
            >
              {{ $t(submenu.headline) }}
            </v-col>
            <!-- locale -->
            <v-col cols="2" class="text-uppercase menu-link" @mouseover="openSubMenu = true">
              {{ $i18n.locale }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- submenu -->
      <template #extension>
        <v-expand-transition>
          <v-container class="mx-0 py-0 pr-9 hidden-sm-and-down" fluid v-show="openSubMenu">
            <v-row justify="end" no-gutters class="pt-3 pb-6">
              <v-col md="6" align-self="center">
                <v-row no-gutters justify="space-around">
                  <!-- neotiv -->
                  <v-col
                    class="pr-7"
                    v-for="(submenu, idx) in mainMenu"
                    :cols="colsWidths[$i18n.locale][idx]"
                    :key="`expand-${submenu.headline}`"
                  >
                    <div
                      v-for="item in submenu.items"
                      :key="`expand-${item.title}`"
                      class="my-3 small submenu-link"
                      @click="openSubMenu = false"
                    >
                      <!-- external link -->
                      <a
                        v-if="item.link"
                        :href="item.link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {{ $t(item.title) }}
                      </a>

                      <!-- internal link -->
                      <router-link
                        v-else
                        :to="{ name: item.routeName }"
                      >
                        {{ $t(item.title) }}
                      </router-link>
                    </div>
                  </v-col>

                  <!-- locale switcher -->
                  <v-col cols="2">
                    <div
                      v-for="language in $i18n.availableLocales"
                      :key="language"
                      class="submenu-link my-3 small"
                    >
                      <router-link
                        :to="{ name: $route.name, params: { lang: language } }"
                      >
                        <span @click="$i18n.locale = language; openSubMenu = false">
                          {{ $i18n.messages[language].language }}
                        </span>
                      </router-link>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-expand-transition>
      </template>
      <!-- v-app-bar-nav-icon with an icon inside doesn't work adequately (v-toolbar__content cuts the icon a bit) -->
      <v-btn
        icon
        @click="$emit('update:openSideMenu', true)"
        class="hidden-md-and-up ma-2"
      >
        <v-icon>
          mdi-menu
        </v-icon>
      </v-btn>

    </v-app-bar>
</template>

<script>
export default {
  name: "neotiv-header",
  props: {
    openSideMenu: Boolean,
    mainMenu: Array
  },
  data() {
    return {
      openSubMenu: true,
      colsWidths: {
        en: [3, 3, 3],
        de: [3, 4, 3]
      }
    }
  },
  computed: {
    logo_large() {
      return require("@/assets/images/logo_header.svg");
    },
    logo_small() {
      return require("@/assets/images/logo.svg");
    }
  }
};
</script>

<style scoped>
/* vuetify default app structure doesn't support max-width, centered layouts */
/* https://github.com/vuetifyjs/vuetify/issues/4267 */
/* vuetify uses inline styles for the left/right properties :( */
.app-header {
  height: auto !important;
  left: auto !important;
  right: auto !important;
  width: 100%;
  max-width: inherit;
}

.app-header :deep(.v-toolbar__content) {
  padding: 0;
}

.menu-link {
  font-size: 20px;
}

.submenu-link > a {
  text-decoration: none;
  cursor: pointer;
  color: #919191;
}

.submenu-link > a:hover {
  color: black;
}

:deep(.v-toolbar__extension){
  height: auto !important;
  padding: 0;
  border-top: 0.01em #919191 solid;
}

.app-header .expand-transition-leave-active,
.app-header .expand-transition-enter-active {
  transition-duration: 0.6s!important;
}
</style>
