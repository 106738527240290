<!--
 Name: Press.vue
 Description: View to display the press page.
 Created by: Jana Schumann on 2019-11-07
 Last edited by: Lokeswari Madhusudhana on 2024-07-30
-->

<template>
  <v-container fluid class="pa-0 full-width">
    <!-- intro -->
    <v-container>
      <h2 class="my-12">{{ $t("press.headline") }}</h2>
      <p class="text-justify mb-12">
        {{ $t("press.text") }}
        <a href="mailto:presse@neotiv.com">presse@neotiv.com</a>.
      </p>
    </v-container>
    <v-divider />

    <!-- info material -->
    <section id="informational-material" class="my-12">
      <v-container class="pb-6">
        <h2 class="mb-15 text-center">
          {{ $t("press.informational_material") }}
        </h2>
        <v-row justify="center">
          <v-col cols="12" sm="3">
            <v-img :src="cover_pdf" class="border" width="154px"></v-img>
            <p class="mt-3">
              <b>Press release re.cogni.ze (English/Int’l)</b>
              <br />
              <a
                href="/static/pdfs/20240728_press_release_AAIC_EN.pdf"
                target="_blank"
                rel="noopener noreferrer"
                v-if="$i18n.locale === 'en'"
                >{{ $t("press.download") }}</a
              >
              <a
                href="/static/pdfs/20240728_press_release_AAIC_EN.pdf"
                target="_blank"
                rel="noopener noreferrer"
                v-if="$i18n.locale === 'de'"
                >{{ $t("press.download") }}</a
              >
            </p>
          </v-col>
          <v-col cols="12" sm="3">
            <v-img :src="cover_pdf" class="border" width="154px"></v-img>
            <p class="mt-3">
              <b>Press release re.cogni.ze (German)</b>
              <br />
              <a
                href="/static/pdfs/20240729_press_release_AAIC_DE.pdf"
                target="_blank"
                rel="noopener noreferrer"
                v-if="$i18n.locale === 'en'"
                >{{ $t("press.download") }}</a
              >
              <a
                href="/static/pdfs/20240729_press_release_AAIC_DE.pdf"
                target="_blank"
                rel="noopener noreferrer"
                v-if="$i18n.locale === 'de'"
                >{{ $t("press.download") }}</a
              >
            </p>
          </v-col>
          <v-col cols="12" sm="3">
            <v-img :src="cover_pdf" class="border" width="154px"></v-img>
            <p class="mt-3">
              <b>re.cogni.ze chart: Added value HCP</b>
              <br />
              <a
                href="/static/pdfs/chart_hcp.jpg"
                target="_blank"
                rel="noopener noreferrer"
                v-if="$i18n.locale === 'en'"
                >{{ $t("press.download") }}</a
              >
              <a
                href="/static/pdfs/chart_hcp.jpg"
                target="_blank"
                rel="noopener noreferrer"
                v-if="$i18n.locale === 'de'"
                >{{ $t("press.download") }}</a
              >
            </p>
          </v-col>
          <v-col cols="12" sm="3">
            <v-img :src="cover_pdf" class="border" width="154px"></v-img>
            <p class="mt-3">
              <b>re.cogni.ze chart: Patient ease of use</b>
              <br />
              <a
                href="/static/pdfs/chart_patient.jpg"
                target="_blank"
                rel="noopener noreferrer"
                v-if="$i18n.locale === 'en'"
                >{{ $t("press.download") }}</a
              >
              <a
                href="/static/pdfs/chart_patient.jpg"
                target="_blank"
                rel="noopener noreferrer"
                v-if="$i18n.locale === 'de'"
                >{{ $t("press.download") }}</a
              >
            </p>
          </v-col>
          <v-col cols="12" sm="3">
            <v-img :src="cover_pdf" class="border" width="154px"></v-img>
            <p class="mt-3">
              <b>re.cogni.ze author Prof. Dr. med. Emrah Düzel</b>
              <br />
              <a
                href="/static/pdfs/Emrah.jpg"
                target="_blank"
                rel="noopener noreferrer"
                v-if="$i18n.locale === 'en'"
                >{{ $t("press.download") }}</a
              >
              <a
                href="/static/pdfs/Emrah.jpg"
                target="_blank"
                rel="noopener noreferrer"
                v-if="$i18n.locale === 'de'"
                >{{ $t("press.download") }}</a
              >
            </p>
          </v-col>
          <v-col cols="12" sm="3">
            <v-img :src="cover_pdf" class="border" width="154px"></v-img>
            <p class="mt-3">
              <b>re.cogni.ze author Dr. Michael Schöttler</b>
              <br />
              <a
                href="/static/pdfs/Michael.jpg"
                target="_blank"
                rel="noopener noreferrer"
                v-if="$i18n.locale === 'en'"
                >{{ $t("press.download") }}</a
              >
              <a
                href="/static/pdfs/chart_hcp.jpg"
                target="_blank"
                rel="noopener noreferrer"
                v-if="$i18n.locale === 'de'"
                >{{ $t("press.download") }}</a
              >
            </p>
          </v-col>
          <v-col cols="12" sm="3" v-if="$i18n.locale === 'en'">
            <v-img :src="cover_pdf" class="border" width="154px"></v-img>
            <p class="mt-3">
              <b>{{ $t("press.release_adriddle") }} </b>
              <br />
              <a
                href="https://neotiv.com/static/pdfs/2024-01_Press_Release_neotiv_AD-RIDDLE.pdf"
                target="_blank"
                rel="noopener noreferrer"
                >{{ $t("press.download") }}</a
              >
            </p>
          </v-col>
          <v-col cols="12" sm="3">
            <v-img :src="cover_founders" class="border" width="154px"></v-img>
            <p class="mt-3">
              <b>{{ $t("press.release_preseriesa") }} </b>
              <br />
              <a
                href="https://neotiv.com/static/pdfs/20211109_Press_Release_PreSeriesA_EN_FINAL.pdf"
                target="_blank"
                rel="noopener noreferrer"
                v-if="$i18n.locale === 'en'"
                >{{ $t("press.download") }}</a
              >
              <a
                href="https://neotiv.com/static/pdfs/20211109_Pressemitteilung_PreSeriesA_DE_FINAL.pdf"
                target="_blank"
                rel="noopener noreferrer"
                v-if="$i18n.locale === 'de'"
                >{{ $t("press.download") }}</a
              >
            </p>
          </v-col>
          <v-col cols="12" sm="3">
            <v-img :src="cover_pdf" class="border" width="154px"></v-img>
            <p class="mt-3">
              <b>{{ $t("press.release_dzne") }} </b>
              <br />
              <a
                href="https://neotiv.com/static/pdfs/Press_Release_Collaboration_DZNE_EN.pdf"
                target="_blank"
                rel="noopener noreferrer"
                v-if="$i18n.locale === 'en'"
                >{{ $t("press.download") }}</a
              >
              <a
                href="https://neotiv.com/static/pdfs/Pressemeldung_Kooperation_DZNE_DE.pdf"
                target="_blank"
                rel="noopener noreferrer"
                v-if="$i18n.locale === 'de'"
                >{{ $t("press.download") }}</a
              >
            </p>
          </v-col>
          <v-col cols="12" sm="3">
            <v-img :src="cover_pdf" class="border" width="154px"></v-img>
            <p class="mt-3">
              <b>{{ $t("press.handout") }} (03/2019)</b>
              <br />
              <a
                href="https://neotiv.com/static/pdfs/20190313_neotiv_pressehandout.pdf"
                target="_blank"
                rel="noopener noreferrer"
                >{{ $t("press.download") }}</a
              >
            </p>
          </v-col>
        </v-row>
      </v-container>
      <v-divider />
    </section>

    <!-- press review -->
    <section id="press_review" class="my-12">
      <v-container>
        <h2 class="mb-15 text-center">{{ $t("press.review") }}</h2>
        <v-row class="mb-5">
          <v-col cols="12">
            <ul>
              <li>
                <a
                  href="https://neotiv.com/static/pdfs/20201222_press_release_H70.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  >22.12.2020 The University of Gothenburg and neotiv GmbH enter cooperation on the H70 cohort study</a
                >
              </li>
              <li>
                <a
                  href="https://neotiv.com/static/pdfs/Pressemeldung_neotiv_RoX_Kooperation.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  >14.09.2020 Versorgungsrelevante Partnerschaften stärken die Digitalisierung - neotiv und RoX Health gehen Kooperation ein</a
                >
              </li>
              <li>
                <a
                  href="https://www.dw.com/en/citizen-scientists-and-an-app-for-alzheimers-disease/a-47699002"
                  target="_blank"
                  rel="noopener noreferrer"
                  >27.02.2019 Deutsche Welle</a
                >
              </li>
              <li>
                <a
                  href="https://www.aerzteblatt.de/nachrichten/101281/Neue-App-ermoeglicht-Buergerbeteiligung-an-der-Gedaechtnisforschung"
                  target="_blank"
                  rel="noopener noreferrer"
                  >22.02.2019 Deutsches Ärzteblatt</a
                >
              </li>
              <li>
                <a
                  href="https://www.deutschlandfunkkultur.de/alzheimer-forschung-das-geschaeft-mit-dem-alter.976.de.html?dram:article_id=441629"
                  target="_blank"
                  rel="noopener noreferrer"
                  >21.02.2019 Deutschlandfunk</a
                >
              </li>
              <li>
                <a
                  href="https://www.bunte.de/fitness/gesundheit/alzheimer-kampf-gegen-die-krankheit-so-bleibst-du-fit-im-kopf.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  >07.02.2019 BUNTE</a
                >
              </li>
            </ul>
          </v-col>
        </v-row>
      </v-container>
      <v-divider />
    </section>

    <!-- color-scheme -->
    <section id="color-scheme" class="my-12">
      <v-container class="pb-6">
        <h2 class="mb-15 text-center">{{ $t("press.color_scheme") }}</h2>

        <v-row justify="space-around">
          <v-col cols="12" sm="6" lg="2">
            <div class="circle-container mb-12">
              <div class="circle bleu-canard"></div>
            </div>
            <p class="color-description">
              <b>Bleu canard</b>
              <br />
              #00879B
              <br />
              R 0/G 135/B 155
              <br />
              C 81/M 26/Y 32/K 8
              <br />
              Pantone 3145 C
            </p>
          </v-col>
          <v-col cols="12" sm="6" lg="2">
            <div class="circle-container mb-12">
              <div class="circle violet"></div>
            </div>
            <p class="color-description">
              <b>Violet</b>
              <br />
              #57509C
              <br />
              R 87/G 80/B 156
              <br />
              C 77/M 73/Y 0/K 0
              <br />
              Pantone Violet C
            </p>
          </v-col>
          <v-col cols="12" sm="6" lg="2">
            <div class="circle-container mb-12">
              <div class="circle serenity-bleu"></div>
            </div>
            <p class="color-description">
              <b>Serenity Bleu</b>
              <br />
              #4E90BD
              <br />
              R 78/G 144/B 189
              <br />
              C 70/M 33/Y 12/K 1
              <br />
              Pantone 7461 C
            </p>
          </v-col>
          <v-col cols="12" sm="6" lg="2">
            <div class="circle-container mb-12">
              <div class="circle clinic-green"></div>
            </div>
            <p class="color-description">
              <b>Clinic Green</b>
              <br />
              #61D2C3
              <br />
              R 97/G 210/B 195
              <br />
              C 58/M 0/Y 32/K 0
              <br />
              Pantone 7465 C
            </p>
          </v-col>
          <v-col cols="12" sm="6" lg="2">
            <div class="circle-container mb-12">
              <div class="circle sky-blue"></div>
            </div>
            <p class="color-description">
              <b>Sky Blue</b>
              <br />
              #8BDEE8
              <br />
              R 139/G 222/B 232
              <br />
              C 46/M 0/Y 14/K 0
              <br />
              Pantone 630 C
            </p>
          </v-col>
        </v-row>
      </v-container>
      <v-divider />
    </section>

    <!-- logos -->
    <section id="logo" class="my-12">
      <v-container class="pb-6">
        <h2 class="mb-15 text-center">{{ $t("press.logo") }}</h2>
        <!-- neotiv logos -->
        <v-row justify="center">
          <v-col cols="12" sm="5">
            <v-img
              class="border"
              :src="logo_landscape"
              max-width="454"
              cover
            />
            <p class="mt-3">
              <b>{{ $t("press.logo_landscape") }} </b>
              <br />
              <a
                :href="logo_landscape"
                target="_blank"
                :download="$t('logo_neotiv_landscape.png')"
                >{{ $t("press.logo_download") }}</a
              >
            </p>
          </v-col>
          <v-col cols="12" offset-sm="1" sm="5">
            <v-img
              class="border"
              max-width="169"
              :src="logo_portrait"
              cover
            />
            <p class="mt-3">
              <b>{{ $t("press.logo_portrait") }} </b>
              <br />
              <a
                :href="logo_portrait"
                target="_blank"
                :download="$t('logo_neotiv_portrait.png')"
                >{{ $t("press.logo_download") }}</a
              >
            </p>
          </v-col>
        </v-row>
        <!-- neotivCare logos -->
        <v-row justify="center">
          <v-col cols="12" sm="5">
            <v-img
              class="border"
              :src="logo_ncare_landscape"
              max-width="454"
              cover
            />
            <p class="mt-3">
              <b>{{ $t("press.logo_ncare_landscape") }} </b>
              <br />
              <a
                :href="logo_ncare_landscape"
                target="_blank"
                :download="$t('logo_neotivCare_landscape.png')"
                >{{ $t("press.logo_download") }}</a
              >
            </p>
          </v-col>
          <v-col cols="12" offset-sm="1" sm="5">
            <v-img
              class="border"
              max-width="169"
              :src="logo_ncare_portrait"
              cover
            />
            <p class="mt-3">
              <b>{{ $t("press.logo_ncare_portrait") }} </b>
              <br />
              <a
                :href="logo_ncare_portrait"
                target="_blank"
                :download="$t('logo_neotivCare_portrait.png')"
                >{{ $t("press.logo_download") }}</a
              >
            </p>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </v-container>
</template>

<script>
export default {
  computed: {
    logo_landscape() {
      return require("@/assets/images/press/logo_landscape.png");
    },
    logo_portrait() {
      return require("@/assets/images/press/logo_portrait.png");
    },
    logo_ncare_landscape() {
      return require("@/assets/images/press/logo_neotivCare_landscape.png");
    },
    logo_ncare_portrait() {
      return require("@/assets/images/press/logo_neotivCare_portrait.png");
    },
    cover_pdf() {
      return require("@/assets/images/press/cover_pdf.png");
    },
    cover_founders() {
      return require("@/assets/images/press/neotivFounders.png");
    }
  }
};
</script>

<style scoped>
.circle {
  background-color: red;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  height: auto;
  width: 100%;
  padding-top: 100%;
}
.bleu-canard {
  background-color: #00879b;
}
.violet {
  background-color: #57509c;
}
.serenity-bleu {
  background-color: #4e90bd;
}
.clinic-green {
  background-color: #61d2c3;
}
.sky-blue {
  background-color: #8bdee8;
}
.circle-container {
  width: 74%;
  margin: 0 auto;
}
.color-description {
  /*circle width - 70% => margin = (100% - 74%) / 2*/
  margin-left: 13%;
}
.border {
  border: 1px solid #d9d9d9;
}
</style>
