<!--
 Name: App.vue
 Description: Base component.
 Created by: Jana Schumann on 2019-11-05
 Last edited by: Lokeswari Madhusudhana on 2024-04-18
-->

<template>
  <v-app id="app-root">

    <!-- side menu shown only on small devices -->
    <app-side-menu
      class="hidden-md-and-up"
      v-model:openSideMenu="openSideMenu"
      :mainMenu="mainMenu"
    />

    <!-- header (with the menu shown on large devices) -->
    <app-header
      v-model:openSideMenu="openSideMenu"
      :mainMenu="mainMenu"
    />

    <!-- page content -->
    <v-main class="bg-white">
      <router-view/>
    </v-main>

    <!-- footer -->
    <app-footer
      :mainMenu="mainMenu"
    />

    <cookie-banner :key="$route.path"/>
  </v-app>
</template>

<script>
import Footer from "./components/Footer.vue";
import Header from "./components/Header.vue";
import SideMenu from "./components/SideMenu.vue";
import CookieBanner from "./components/CookieBanner.vue";

export default {
  name: "App",
  components: {
    appFooter: Footer,
    appHeader: Header,
    appSideMenu: SideMenu,
    CookieBanner
  },
  data() {
    return {
      openSideMenu: false,
      languageSwitch: null
    }
  },
  computed: {
    // computed to make the locale dynamic
    mainMenu() {
      return [
        {
          headline: "footer.neotiv.headline",
          items: [
            { title: "footer.neotiv.team", routeName: "Team" },
            { title: "footer.neotiv.partnerships", routeName: "Partnerships" },
            { title: "footer.neotiv.press", routeName: "Press" },
            { title: "footer.neotiv.awards", routeName: "Awards" },
            { title: "footer.neotiv.certificates", routeName: "Certificates" },
            { title: "footer.neotiv.jobs", link: `https://neotiv.jobs.personio.de/?language=${this.$i18n.locale}` }
          ]
        },
        {
          headline: "footer.science.headline",
          items: [
            { title: "footer.science.background", routeName: "Background" },
            { title: "footer.science.hiw", routeName: "HowItWorks" },
            { title: "footer.science.validation", routeName: "ValidationStrategy" },
            { title: "footer.science.studies", routeName: "Studies" },
            { title: "footer.science.papers", routeName: "Papers" }
          ]
        },
        {
          headline: "footer.professionals.headline",
          items: [
            { title: "footer.professionals.scientists", routeName: "Scientists" },
            { title: "footer.professionals.researchers", routeName: "Researchers" },
            { title: "footer.professionals.physicians", link: `https://neotiv-care.com/medizinisches-fachpersonal` }
          ]
        }
      ]
    }
  }
};
</script>

<style>
/* we need to refactor these styles at some point and introduce sass variables */

/* Hind regular */

@font-face {
  font-family: "Hind";
  src: url("https://neotiv.com/static/fonts/Hind-Regular.eot");
  src: url("https://neotiv.com/static/fonts/Hind-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("https://neotiv.com/static/fonts/Hind-Regular.woff2") format("woff2"),
    url("https://neotiv.com/static/fonts/Hind-Regular.woff") format("woff"),
    url("https://neotiv.com/static/fonts/Hind-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

/* Hind semi bold */

@font-face {
  font-family: "Hind";
  src: url("https://neotiv.com/static/fonts/Hind-SemiBold.eot");
  src: url("https://neotiv.com/static/fonts/Hind-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("https://neotiv.com/static/fonts/Hind-SemiBold.woff2") format("woff2"),
    url("https://neotiv.com/static/fonts/Hind-SemiBold.woff") format("woff"),
    url("https://neotiv.com/static/fonts/Hind-SemiBold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

/* Ruluko regular */

@font-face {
  font-family: "Ruluko";
  src: url("https://neotiv.com/static/fonts/Ruluko-Regular.eot");
  src: url("https://neotiv.com/static/fonts/Ruluko-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("https://neotiv.com/static/fonts/Ruluko-Regular.woff2") format("woff2"),
    url("https://neotiv.com/static/fonts/Ruluko-Regular.woff") format("woff"),
    url("https://neotiv.com/static/fonts/Ruluko-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

body {
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.45;
}

.v-application {
  font-family: "Hind", sans-serif !important;
}

/* App layout */
#app-root {
  background-color: #efefef;
}
/* vuetify default app structure doesn't support max-width, centered layouts */
/* https://github.com/vuetifyjs/vuetify/issues/4267 */
.v-application__wrap {
  max-width: 1440px !important;
  margin: auto;
}

/* Fix for the images on small devices in Safari */
.v-responsive__content {
  width: unset !important;
}

/* a lot of the styles here have to be moved to a dedicated file with global styles */
/* + sass variables have to be introduced */
.v-btn {
  border-width: 2px !important;
  font-family: "Hind", sans-serif !important;
  font-size: 20px !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
  text-indent: 0 !important;
}

.v-divider {
  border-color: #757575;
}

@media only screen and (min-width: 1264px) {
  .v-main .v-container:not(.full-width), footer .v-container{
    max-width: 1185px !important;
  }
}

a {
  color: #57509c;
}

* {
  text-transform: none !important;
}

.v-carousel__controls {
  background-color: white !important;
}

iframe {
  max-width: 100%;
}

/* fade animation */

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  transition: opacity 0.4s;
}

.fade-leave {
  opacity: 1;
}

.fade-leave-active {
  transition: opacity 0.4s;
  opacity: 0;
}

/* end of fade animation */

.square-left {
  width: 50px;
  height: 7px;
  background-color: #91D1DE;
  border-color: #91D1DE;
}

.square-right {
  width: 50px;
  height: 7px;
  margin-right: 0;
  margin-left: auto;
}

.v-input__slot,
.theme--light.v-text-field--solo .v-input__slot {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.small {
  font-size: 16px;
}

.semi-small {
  font-size: 17px;
}

.font-nineteen {
  font-size: 19px;
}

/* dialogs / cards */
.v-dialog>.v-card>.v-card__title {
  /*https://github.com/vuetifyjs/vuetify/issues/9130*/
  word-break: normal!important;
  font-size: 30px!important;
}
.v-dialog>.v-card>.v-card__text, .v-dialog>.v-card .v-btn {
  font-size: inherit!important;
  line-height: inherit;
}
</style>
