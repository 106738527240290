<!--
 Name: Home.vue
 Description: View to display the home page.
 Created by: Jana Schumann on 2019-11-06
 Last edited by: Jana Schumann on 2024-01-09
-->

<template>
  <v-container fluid class="pa-0 full-width">
    <!-- news banner -->
    <news-banner v-if="$i18n.locale === 'de'"/>
    <!-- hero graphic -->
    <hero-component
      :title="$t('hero.home-title')"
      :text="$t('hero.home-text')"
      :image="require('@/assets/images/home/hero.svg')"
      :ratio="1"
      :heroColor="'#57509C'"
    >
    </hero-component>
    
    <!-- product -->
    <section id="all" class="bg-white">
      <v-container>
        <h2 class="text-center my-12 pt-12">
          {{ $t("home.product-title-1") }}
          <br />
          {{ $t("home.product-title-2") }}
        </h2>
      </v-container>
    </section>

    <v-container>
      <!-- cognitive tests -->
      <v-row class="my-12">
        <v-col cols="12" md="5" class="text-blocks">
          <div class="square-left bg-primary mb-8"></div>
          <h3 class="mb-3">{{ $t("home.cognitive-tests-title") }}</h3>
          <p class="mb-3">{{ $t("home.cognitive-tests-text-1") }}</p>
          <p class="mb-3">{{ $t("home.cognitive-tests-text-2") }}</p>
          <p class="mb-3">{{ $t("home.cognitive-tests-text-3") }}</p>
        </v-col>
        <v-col cols="12" md="6" offset-md="1">
          <v-img
            :src="
              $i18n.locale === 'de' ? cognitive_tests_de : cognitive_tests_en
            "
            height="400"
          />
        </v-col>
      </v-row>

      <!-- how it works -->
      <v-row justify="center" class="my-12">
        <v-col cols="12" sm="9" md="7" lg="5">
          <v-btn
            size="large"
            variant="outlined"
            color="primary"
            block
            :ripple="false"
            class="my-12 py-7 block-divider-btn px-3"
            :href="'/' + $i18n.locale + '/how'"
          >
            {{ $t("home.how-it-works-btn") }}
          </v-btn>
        </v-col>
      </v-row>

      <!-- digital platform -->
      <v-row class="my-12 py-12">
        <v-col cols="12" md="6" order="2" order-md="1">
          <v-img
            :src="digitalPlatform"
            height="300"
          />
        </v-col>
        <v-col cols="12" md="5" offset-md="1" order="1" order-md="2" class="text-blocks">
          <div class="square-left bg-primary mb-8"></div>
          <h3 class="mb-3">{{ $t("home.digital-platform-title") }}</h3>
          <p>{{ $t("home.digital-platform-text") }}</p>
        </v-col>
      </v-row>

      <!-- testing -->
      <v-row class="my-12">
        <v-col cols="12" md="5" class="text-blocks">
          <div class="square-left bg-primary mb-8"></div>
          <h3 class="mb-3">{{ $t("home.testing-title") }}</h3>
          <p class="mb-3">{{ $t("home.testing-text-1") }}</p>
          <p class="mb-3">{{ $t("home.testing-text-2") }}</p>
        </v-col>
        <v-col cols="12" md="6" offset-md="1">
          <v-img
            :src="testing"
            height="300"
          />
        </v-col>
      </v-row>

      <!-- scientific background -->
      <v-row justify="center" class="my-12">
        <v-col cols="12" sm="9" md="7" lg="5">
          <v-btn
            size="large"
            variant="outlined"
            block
            :ripple="false"
            color="primary"
            class="my-12 py-7 block-divider-btn px-3"
            :href="'/' + $i18n.locale + '/background'"
          >
            {{ $t("home.scientific-background") }}
          </v-btn>
        </v-col>
      </v-row>

      <!-- validation -->
      <v-row class="my-12 pt-12">
        <v-col cols="12" md="6" order="2" order-md="1">
          <v-img
            :src="validation"
            height="300"
          />
        </v-col>
        <v-col cols="12" md="5" offset-md="1" order="1" order-md="2" class="text-blocks">
          <div class="square-left bg-primary mb-8"></div>
          <h3 class="mb-3">{{ $t("home.certification-title") }}</h3>
          <p class="mb-3">{{ $t("home.certification-text-1") }}</p>
          <p class="mb-3">{{ $t("home.certification-text-2") }}</p>
          <p class="mb-3">{{ $t("home.certification-text-3") }}</p>
        </v-col>
      </v-row>
    </v-container>

    <!-- professionals -->
    <section class="violet_light">
      <v-container>
        <h2 class="text-center mt-12 mb-15">
          {{ $t("home.professionals-title") }}
        </h2>
        <v-row class="mb-12 professionals" justify="space-around">
          <v-col cols="12" sm="3">
            <v-btn
              class="py-8"
              size="large"
              width="100%"
              color="primary"
              :to="'/' + $i18n.locale + '/scientists'"
              >{{ $t("footer.professionals.scientists") }}</v-btn
            >
          </v-col>
          <v-col cols="12" sm="3">
            <v-btn
              class="py-8"
              size="large"
              width="100%"
              color="primary"
              :to="'/' + $i18n.locale + '/researchers'"
              >{{ $t("footer.professionals.researchers") }}</v-btn
            >
          </v-col>
          <v-col cols="12" sm="3">
            <v-btn
              class="py-8"
              size="large"
              width="100%"
              color="primary"
              href="https://neotiv-care.com/medizinisches-fachpersonal"
              target="_blank"
              >{{ $t("footer.professionals.physicians") }}</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </section>

    <!-- contact form -->
    <section class="bg-primary">
      <v-container class="py-12">
        <v-container>
          <h3 class="text-white text-center">
            {{ $t("home.contact-title") }}
          </h3>
          <p class="text-white mt-7">{{ $t("home.contact-text") }}</p>
        </v-container>
        <contact-form
          :formColor="'#57509C'"
          :formBtnColor="'#6DC1B9'"
          :newsletter="false"
        />
      </v-container>
    </section>

  </v-container>
</template>

<script>
import ContactForm from "@/components/Form";
import HeroComponent from "@/components/Hero";
import NewsBanner from "../components/NewsBanner";

export default {
  components: {
    ContactForm,
    HeroComponent,
    NewsBanner
  },
  data: () => ({
    overlay: false
  }),
  computed: {
    cognitive_tests_en() {
      return require("@/assets/images/home/cognitive_tests_en.png");
    },
    cognitive_tests_de() {
      return require("@/assets/images/home/cognitive_tests_de.png");
    },
    digitalPlatform() {
      return require("@/assets/images/home/digital_platform_de.png");
    },
    product_app() {
      return require("@/assets/images/home/product_app.svg");
    },
    product_cognitive_tests() {
      return require("@/assets/images/home/product_cognitive_tests.svg");
    },
    product_trials() {
      return require("@/assets/images/home/product_trials.svg");
    },
    testing() {
      return require("@/assets/images/home/testing.svg");
    },
    validation() {
      return require("@/assets/images/home/validation.svg");
    }
  }
};
</script>

<style>
.professionals .v-btn span {
  font-size: 23px !important;
  margin-top: -16px;
}

.block-divider-btn {
  border-radius: 9px !important;
  white-space: normal !important;
  text-align: center;
}

.block-divider-btn:hover {
  border-color: #393768;
}

/* mobile view fix */
.block-divider-btn > span {
  display: contents;
}

.text-blocks {
  line-height: 1.3;
}

.violet_light {
  background-color: #dddbeb !important;
  border-color: #dddbeb !important;
}
</style>
